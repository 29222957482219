<div class="row text-center">
  <div class="col-md-4">
    <mat-button-toggle-group>
      <mat-button-toggle
        mwlCalendarPreviousView
        [view]="view"
        [(viewDate)]="viewDate"
        (viewDateChange)="closeOpenMonthViewDay()">Previous</mat-button-toggle>
      <mat-button-toggle
        mwlCalendarToday
        [(viewDate)]="viewDate">Today</mat-button-toggle>
      <mat-button-toggle
        class="btn btn-primary"
        mwlCalendarNextView
        [view]="view"
        [(viewDate)]="viewDate"
        (viewDateChange)="closeOpenMonthViewDay($event)">Next</mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <div class="col-md-4">
    <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3>
  </div>
</div>
<br />
<div [ngSwitch]="view">
  <mwl-calendar-month-view
    *ngSwitchCase="CalendarView.Month"
    [viewDate]="viewDate"
    [events]="calendarEvents"
    [openDayEventsTemplate]="openDayEventsTemplate"
    (dayClicked)="dayClicked($event.day)"
    [activeDayIsOpen]="activeDayIsOpen"
    (beforeViewRender)="updateCalendarEvents($event)"
 />
  <mwl-calendar-week-view
    *ngSwitchCase="CalendarView.Week"
    [viewDate]="viewDate"
    (beforeViewRender)="updateCalendarEvents($event)"
    [events]="calendarEvents"/>
  <mwl-calendar-day-view
    *ngSwitchCase="CalendarView.Day"
    [viewDate]="viewDate"
    (beforeViewRender)="updateCalendarEvents($event)"
    [events]="calendarEvents"/>
</div>

  <ng-template  #openDayEventsTemplate let-isOpen="isOpen">
    <div class="cal-open-day-events" [@collapse] *ngIf="isOpen">
      <ng-container>
        <h2 class="active-text">User is Availabile From:</h2>
        <p class="active-text" *ngIf="currentRanges.length === 0">
          User is unavailable on this day.
        </p>
        <p class="active-text" *ngFor="let range of currentRanges">
          Start: {{range.startTime | militaryTo12Hour}} - End: {{range.endTime| militaryTo12Hour}}
        </p>
      </ng-container>
    </div>
  </ng-template>

