import { TitleCasePipe } from '@angular/common';
import { Component, computed, DestroyRef, inject, input, signal } from '@angular/core';
import { takeUntilDestroyed, toObservable, toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { Router, RouterModule } from '@angular/router';
import { ArkLinkCardComponent } from '@ark/components/link-card/link-card.component';
import { DefaultEventIcon, EventTypeIcons } from '@index/enums';
import { EndorsementType } from '@index/interfaces';
import { GthUserModel } from '@sentinels/models';
import { SendRequestDialogComponent } from '@shared/dialogs/send-request-dialog/send-request-dialog.component';
import { APP_ROUTES } from '@shared/helpers';
import { filter, switchMap } from 'rxjs';

import { EndorsementsService } from '../../../../../../../gth-legacy/src/public-api';
import { AvailabilityDialogComponent } from '../../../settings/availability/dialogs/availability-dialog.component';

const GENERAL_CARD_INFO = {
  photoURL: 'assets/img/golfer.svg',
  title: 'Find more users<br/>near you!',
};

@Component({
  selector: 'app-user-card',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.scss'],
  standalone: true,
  imports: [
    MatButtonModule,
    RouterModule,
    MatIconModule,
    MatChipsModule,
    TitleCasePipe,
    ArkLinkCardComponent,
  ],
})
export class UserCardComponent {
  user = input<GthUserModel>();

  showInviteBtn = input<boolean>(false);
  showCalendarBtn = input<boolean>(false);

  generalCard = input<boolean>(false);
  generalCardInfo = computed(() =>
    this.generalCard() ? GENERAL_CARD_INFO : null,
  );

  displayBio = computed<string>(() => {
    return this.user().bio || 'This player has not set their bio yet!';
  });
  public routes = APP_ROUTES;
  cardLink = computed(() =>
    this.generalCard() ? this.routes.DiscoverPlayers : [this.routes.Profile, this.user().uid],
  );

  queryParams = signal({ context: 'participants' });

  eventIcons = EventTypeIcons;
  defaultEventIcon = DefaultEventIcon;

  private endorsementsService = inject(EndorsementsService);
  private destroyRef = inject(DestroyRef);
  private router = inject(Router);
  private dialog = inject(MatDialog);

  private endorsements = toSignal(
    toObservable(this.user).pipe(
      filter((user) => !!user.uid),
      takeUntilDestroyed(this.destroyRef),
      switchMap((user) => this.endorsementsService.getEndorsements$(user.uid)),
    ),
    { initialValue: [] },
  );

  passions = computed(() =>
    this.endorsements().filter((endorsement) =>
      endorsement.type === EndorsementType.PASSION,
    ).slice(0, 3),
  );

  traits = computed(() =>
    this.endorsements().filter((endorsement) =>
      endorsement.type === EndorsementType.PERSONALITY,
    ).slice(0, 3),
  );

  onMessageBtnClick(event: MouseEvent, userId: string) {
    event.stopPropagation();

    this.router.navigate([APP_ROUTES.Messages], {
      queryParams: {
        userId,
      },
    });
  }

  onInviteBtnClick(event: MouseEvent) {
    event.stopPropagation();

    this.dialog.open(SendRequestDialogComponent, {
      id: 'send-request-dialog',
      backdropClass: 'gth-overlay-backdrop',
      panelClass: 'gth-dialog--custom-size',
      width: '720px',
      height: '500px',
      data: {
        player: this.user(),
      },
    });
  }

  onCalendarBtnClick(event: MouseEvent) {
    event.stopPropagation();

    this.dialog.open(AvailabilityDialogComponent, {
      data: {
        availability: this.user().userAvailability,
        user: this.user(),
        day: null,
      },
      width: '750px',
      backdropClass: 'gth-overlay-backdrop',
      panelClass: 'gth-dialog--custom-size',
    });
  }
}
