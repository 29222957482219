import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, inject, OnDestroy, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { EventsService } from '@gth-legacy/services/events.service';
import { EventJoiner, EventJoinerStatus, EventRsvpStatus } from '@index/interfaces/event-item';
import { Store } from '@ngrx/store';
import { GthEventItemModel, GthUserModel } from '@sentinels/models';
import { selectUser } from '@sentinels/state/features/auth/selectors';
import { selectEventsByCreatorId } from '@sentinels/state/features/events/selectors';
import { forkJoin, Subscription } from 'rxjs';

import { APP_STATE, eventLoadListByUserId } from '../../../../../../gth-legacy/src/public-api';

@Component({
  selector: 'app-send-request-dialog',
  templateUrl: './send-request-dialog.component.html',
  styleUrls: ['./send-request-dialog.component.scss'],
  imports: [
    MatDialogModule,
    MatTableModule,
    MatCheckboxModule,
    MatButtonModule,
    MatIconModule,
    DatePipe,
  ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SendRequestDialogComponent implements OnInit, OnDestroy {
  displayedColumns = ['select', 'name', 'sport', 'date'];
  dataSource = new MatTableDataSource<GthEventItemModel>([]);
  selection = new SelectionModel<GthEventItemModel>(true, []);
  loading = true;
  private subscriptions = new Subscription();

  private store = inject(Store<APP_STATE>);
  private snackbar = inject(MatSnackBar);

  user = this.store.selectSignal(selectUser);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      player: GthUserModel,
    },
    private events: EventsService,
    private dialogRef: MatDialogRef<SendRequestDialogComponent>,
  ) { }

  ngOnInit() {
    this.store.dispatch(eventLoadListByUserId({ id: this.user().id }));
    this.dataSource.data = this.store.selectSignal(selectEventsByCreatorId(this.user().id))();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  /**
   * Whether the number of selected elements matches the total number of rows.
   * @return {boolean} is all selected
   * */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /**
   * Selects all rows if they are not all selected; otherwise clear selection.
   * @return {undefined}
   * */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  /**
   * The label for the checkbox on the passed row
   * @param {GthEventItemModel} event Event of row
   * @return {string} label
   * */
  checkboxLabel(event?: GthEventItemModel): string {
    if (!event) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }

    return `${this.selection.isSelected(event) ? 'deselect' : 'select'} ${event.title}`;
  }

  async onSubmit() {
    if (!this.data.player) return;

    if (this.selection.hasValue()) {
      const requests$: Array<Promise<boolean>> = [];
      this.selection.selected.forEach((evt) => {
        const user = this.data.player;
        const isMember = evt.participants.some((p) => p.player === user.uid);
        if (!isMember) {
          const joiner: EventJoiner = {
            player: user.uid,
            createdAt: Date.now(),
            rsvpStatus: EventRsvpStatus.MAYBE,
          };

          const onJoin$ = this.events.onJoinGame(evt,
            [joiner],
            EventRsvpStatus.MAYBE,
            [],
            false,
            EventJoinerStatus.PendingJoiner);
          requests$.push(onJoin$);
        } else {
          console.log('Player is already a part of this event');
        }
      });

      if (requests$.length === 0) {
        this.dialogRef.close();
      }

      this.subscriptions.add(
        forkJoin(requests$).subscribe((responses) => {
          if (responses.length === requests$.length) {
            this.dialogRef.close();
            this.snackbar.open('Request(s) sent successfully');
          }
        }),
      );

      return;
    }

    this.dialogRef.close();
  }
}
