import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WeeklyAvailability } from '@index/interfaces';
import { GthUserModel, SrvAvailabilityModel } from '@sentinels/models';

interface AvailabilityDialogContract {
  availability?: SrvAvailabilityModel;
  user?: GthUserModel;
  day?: string;
}

@Component({
  selector: 'app-availability-dialog',
  templateUrl: './availability-dialog.component.html',
  styleUrls: ['./availability-dialog.component.scss'],
})
export class AvailabilityDialogComponent {
  get availability() {
    return this.data.availability;
  }

  get user() {
    return this.data.user;
  }

  get day() {
    return this.data.day;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: AvailabilityDialogContract,
  ) { }
}
