<h1>Send Request</h1>

<p>Select which event(s) to invite {{data.player.displayName}} to join:</p>

<div class="table-container fx-auto">
  <table mat-table [dataSource]="dataSource">
    <!-- Checkbox Column -->
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let event">
        <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(event) : null"
          [checked]="selection.isSelected(event)" [aria-label]="checkboxLabel(event)">
        </mat-checkbox>
      </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Event Name </th>
      <td mat-cell *matCellDef="let event"> {{event?.title}} </td>
    </ng-container>

    <!-- Sport Column -->
    <ng-container matColumnDef="sport">
      <th mat-header-cell *matHeaderCellDef> Sport </th>
      <td mat-cell *matCellDef="let event"> {{event?.eventType}} </td>
    </ng-container>

    <!-- Date Column -->
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef> Date </th>
      <td mat-cell *matCellDef="let event">
        <div class="date-container">
          <span>
            <b>{{ event.dateStart | date: 'EEEE, MMMM d, y' }} </b>
          </span>
          <span class="secondary-text">
            at {{ event.dateStart | date: 'h:mm a' }}
          </span>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)">
    <tr *matNoDataRow [style.text-align]="'center'">
      <td colspan="4">You have no events to invite this user to.</td>
    </tr>
  </table>
</div>

<div>
  <button mat-flat-button color="primary" class="submit" [disabled]="selection.isEmpty()" (click)="onSubmit()">
    Invite
  </button>


  <button mat-icon-button class="close-btn" mat-dialog-close><mat-icon>close</mat-icon></button>
</div>