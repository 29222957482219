<ark-link-card [generalCard]="generalCardInfo()" [routerLink]="cardLink()" [queryParams]="queryParams()">
  @defer (when !!user()) {
    <header>
      @if (user().photoURL) {
        <!-- Todo(srevier): replace with ark avatar -->
        <img class="avatar" [src]="user()?.photoURL">
      } @else {
        <mat-icon>person</mat-icon>
      }
      <div>
        <h3>{{ user().displayName }}</h3>
        <p>{{ user().defaultCity.name }}</p>
      </div>
    </header>
    <div class="content">
      <div class="upper-content">
        <p class="bio">{{ displayBio() }}</p>

        <div class="chips-container">
          @if (passions().length > 0) {
            <div class="passions">
              <h4>Passions</h4>
              <ul>
                @for (passion of passions(); track passion) {
                  <li>
                    <mat-icon inline>{{ eventIcons[passion.sport] ?? defaultEventIcon }}</mat-icon>
                    {{ passion.name | titlecase }}
                  </li>
                }
              </ul>
            </div>
          }
          @if (traits().length > 0) {
            <div class="personality">
              <h4>Personality</h4>
              <ul>
                @for (trait of traits(); track trait) {
                  <li>{{ trait.name | titlecase }}</li>
                }
              </ul>
            </div>
          }
        </div>
      </div>
      <div class="lower-content">
        <div>
          <button 
            mat-flat-button 
            color="primary"
            (click)="onMessageBtnClick($event, user().uid)"
          >Message</button>
  
          @if (showInviteBtn()) {
            <button
              mat-stroked-button
              color="secondary"
              (click)="onInviteBtnClick($event)"
            >Invite</button>
          }
        </div>

        @if (showCalendarBtn()) {
          <button
            class="calendar-btn"
            mat-icon-button
            (click)="onCalendarBtnClick($event)"
          >
            <mat-icon>calendar_today</mat-icon>
          </button>
        }
      </div>
    </div>
  }
</ark-link-card>
