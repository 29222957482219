import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { TimeRange } from '@index/interfaces';
import { SrvAvailabilityModel } from '@sentinels/models';
import { GthEventItemModel } from '@sentinels/models/event-item';
import {
  CalendarDayViewBeforeRenderEvent,
  CalendarEvent,
  CalendarMonthViewBeforeRenderEvent,
  CalendarView,
  CalendarWeekViewBeforeRenderEvent,
  collapseAnimation,
} from 'angular-calendar';
import { EventColor } from 'calendar-utils';
import { ViewPeriod } from 'calendar-utils';
import { isSameDay, isSameMonth } from 'date-fns';
import moment from 'moment-timezone';
import { RRule, Weekday } from 'rrule';
interface RecurringEvent {
  title: string;
  color: any;
  rrule?: {
    freq: any;
    bymonth?: number;
    bymonthday?: number;
    byweekday?: any;
  };
}

const colors: Record<string, EventColor> = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
};

@Component({
  selector: 'app-calendar',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: 'calendar.component.html',
  styleUrls: ['calendar.component.scss'],
  animations: [collapseAnimation],
})
export class CalendarComponent implements OnInit {
  @ViewChild('modalContent', { static: true })
  modalContent: TemplateRef<any>;

  @ViewChild('openDayEventsTemplate', { static: true })
  openDayEventsTemplate: TemplateRef<any>;

  @Input()
  availability!: SrvAvailabilityModel;

  @Output()
  handleCalendarClickedEvent = new EventEmitter<GthEventItemModel>();

  currentRanges: TimeRange[] = [];
  view: CalendarView;
  viewPeriod: ViewPeriod;
  activeDayIsOpen = false;
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  modalData: {
    action: string;
    event: CalendarEvent;
  };

  recurringEvents: RecurringEvent[] = [];
  calendarEvents: CalendarEvent[] = [];

  get WeeklyMap() {
    return new Map([
      ['sunday', RRule.SU],
      ['monday', RRule.MO],
      ['tuesday', RRule.TU],
      ['wednesday', RRule.WE],
      ['thursday', RRule.TH],
      ['friday', RRule.FR],
      ['saturday', RRule.SA],
    ]);
  }

  constructor() {
    this.view = CalendarView.Month;
  }

  updateCalendarEvents(
    viewRender?:
      | CalendarMonthViewBeforeRenderEvent
      | CalendarWeekViewBeforeRenderEvent
      | CalendarDayViewBeforeRenderEvent,
  ): void {
    if (
      !this.viewPeriod ||
      !moment(this.viewPeriod.start).isSame(viewRender?.period.start) ||
      !moment(this.viewPeriod.end).isSame(viewRender?.period.end)
    ) {
      this.viewPeriod = viewRender?.period;
      this.calendarEvents = [];

      this.recurringEvents.forEach((event) => {
        const rule: RRule = new RRule({
          ...event.rrule,
          dtstart: moment(viewRender?.period.start|| Date.now()).startOf('month').toDate(),
          until: moment(viewRender?.period.end || Date.now()).endOf('year').toDate(),
        });
        const { title, color } = event;

        let foundKey = null;
        const map = this.WeeklyMap;
        const abrv = event.rrule.byweekday[0];
        for (const [key, value] of map.entries()) {
          if (value === abrv) {
            foundKey = key;
            break;
        }
      }

        rule.all().forEach((date) => {
          const ranges: TimeRange[] = this.availability
          .weeklyAvailability[foundKey].timeRanges;

          this.calendarEvents.push({
            title,
            color,
            start: moment(date).toDate(),
            meta: {
              ranges,
            },
          });
        });
      });

      const dsa = this.availability.dateSpecificAvailability;

      dsa.forEach((a)=>{
        const aDate = new Date(a.date);
       const filtered = this.calendarEvents.filter((e)=> {
          const recurringEvent = new Date(e.start);
          return recurringEvent.getDate() === aDate.getDate() &&
          recurringEvent.getMonth() === aDate.getMonth() &&
          recurringEvent.getFullYear() === aDate.getFullYear();
        });

        if (!filtered.length) {
          this.calendarEvents.push({
            title: 'User Event',
            color: colors.blue,
            start: moment(aDate).toDate(),
            meta: {
              ranges: a.timeRanges,
            },
          });
        }
      });
    }
  }

  ngOnInit(): void {
    this.setReoccuringEvents();
    this.updateCalendarEvents();
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  handleEvent(action: string, event: CalendarEvent): void {
    this.modalData = { event, action };
  }

  closeOpenMonthViewDay(evt?:any) {
    this.activeDayIsOpen = false;
  }

  setReoccuringEvents() {
    this.WeeklyMap.forEach((val, key)=>{
      if (!this.availability || !this.availability.availableOnDay(key)) return;
      this.createWeeklyReoccuringEvent(key, val);
    });
  }

  createWeeklyReoccuringEvent(day:string, abrv: Weekday) {
    this.recurringEvents.push(
      {
        title: `User availability for ${day}`,
        color: colors.blue,
        rrule: {
          freq: RRule.WEEKLY,
          byweekday: [abrv],
        },
      },
    );
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      this.viewDate = date;
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
        const dsa = this.availability.dateSpecificAvailability;
        let dateOveride: TimeRange[]| null = null;
        dsa.forEach((a)=>{
          const aDate = new Date(a.date);
          if (aDate.getDate()=== date.getDate() &&
            aDate.getMonth()=== date.getMonth() &&
            aDate.getFullYear()=== date.getFullYear()
          ) {
            dateOveride = a.timeRanges;
          }
        });
        this.currentRanges = dateOveride?
        dateOveride :
        events[0].meta.ranges;
      }
    }
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'militaryTo12Hour',
})
export class MilitaryTo12HourPipe implements PipeTransform {
  transform(militaryTime: string): string {
    if (!militaryTime) return ''; // Handle empty or null input

    const [hours, minutes] = militaryTime.split(':').map(Number);
    const period = hours >= 12 ? 'PM' : 'AM';
    const hours12 = hours % 12 || 12;
    const paddedMinutes = String(minutes).padStart(2, '0');

    return `${hours12}:${paddedMinutes} ${period}`;
  }
}
